// pages/Slate.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './template.css';
import React from "react";
import {Helmet} from "react-helmet";

const Slate = () => (
	<div class="container">
	<Helmet>
			 <meta charSet="utf-8" />
			 <meta
					name='viewport'
					content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
				/>
			 <title>rithwik j. (slate)</title>
	 </Helmet>

		<div class="row">
		<div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
			<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="Back Arrow">
				<g id="Arrow - Left 2">
				<path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
				</g>
				</svg>
			</a>
		</div>

			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-9 col-xl-9" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
					<div id="ProjectTitle">Designing a Braille Slate for Higher Mathematics</div>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-2 col-xl-2">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Timestamp</div>
					<div class="description">Jan—July 2020</div>
				</a>
			</div>
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-1 col-xl-1">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Type</div>
					<div class="description">Product</div>
				</a>
			</div>
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 mr-0 col-12 col-md-4 col-xl-4 col-s-2 pr-5">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Description</div>
					<div class="description">A Braille Slate designed as tooling to enable Visually Impaired children to pursue higher mathematics. Currently (Prototyping) </div>
				</a>
			</div>
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-2 col-xl-2">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Project Report.pdf</div>
					<div class="description"><a href="../images/Slate/Rithwik—Slate (Jun 2020).pdf"><u>Download</u></a></div>
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 .d-block">
					<img src="../images/Slate/page023.jpg" class="pt-5 d-block" alt="..."/>
				</a>
			</div>
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<img src="../images/Slate/page029.png" class="pt-5 d-block" alt="..."/>
				</a>
			</div>
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<img src="../images/Slate/page030.png" class="pt-5 d-block" alt="..."/>
				</a>
			</div>
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<img src="../images/Slate/page031.png" class="pt-5 d-block" alt="..."/>
				</a>
			</div>
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<img src="../images/Slate/page032.png" class="pt-5 d-block" alt="..."/>
				</a>
			</div>
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<img src="../images/Slate/page034.png" class="pt-5 d-block" alt="..."/>
				</a>
			</div><div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<img src="../images/Slate/page036.png" class="pt-5 d-block" alt="..."/>
				</a>
			</div>

		</div>

		<div class="row">

		</div>
	</div>
);

export default Slate;
