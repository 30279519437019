import React from "react";
import './App.css';
import { BrowserRouter, Route, Switch} from "react-router-dom";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";

import Detail from "./pages/Detail";

import WakeUpVote from "./pages/WakeUpVote";
import LightSupport from "./pages/LightSupport";
import Abhilasha from "./pages/Abhilasha";
import ForusRedEyeReflex from "./pages/ForusRedEyeReflex";
import Muse from "./pages/Muse";
import Slate from "./pages/Slate";
import ParkinsonsAdaptive from "./pages/ParkinsonsAdaptive";

import CeramicsExperiments from "./pages/CeramicsExperiments";
import GenerativeCeramics from "./pages/GenerativeCeramics";
import UserResearch1 from "./pages/UserResearch1";
import ResearchExperiments from "./pages/ResearchExperiments";


import Happy from "./pages/Happy";
import Bicycle from "./pages/Bicycle";
import CuratedByInstagram from "./pages/CuratedByInstagram";


import Vellichor from "./pages/Vellichor";
import TwentyFourFPS from "./pages/24fps";


function App() {
  return (
    <BrowserRouter>
      <div className="rootElement">
        <Switch>
          <Route exact path="/">
            <Home />
			<div id="graph" className="columns is-mobile is-centered">
	 		 </div>
          </Route>
          <Route exact path="/me/about">
            <About />
          </Route>
		  <Route exact path="/me/contact">
            <Contact />
          </Route>
		  <Route path="/detail/all">
            <Detail />
          </Route>
		  <Route exact path="/detail/LightSupport">
            <LightSupport />
          </Route>
		   <Route exact path="/detail/WakeUpVote">
            <WakeUpVote />
          </Route>
		  <Route exact path="/detail/Abhilasha">
            <Abhilasha />
          </Route>
		  <Route exact path="/detail/ForusRedEyeReflex">
            <ForusRedEyeReflex />
          </Route>
		   <Route exact path="/detail/Slate">
            <Slate />
          </Route>
		   <Route exact path="/detail/Muse">
            <Muse />
          </Route>
          <Route exact path="/detail/ParkinsonsAdaptive">
            <ParkinsonsAdaptive />
          </Route>

		   <Route exact path="/detail/CeramicsExperiments">
            <CeramicsExperiments />
          </Route>
		   <Route exact path="/detail/GenerativeCeramics">
            <GenerativeCeramics />
          </Route>
		   <Route exact path="/detail/UserResearch1">
            <UserResearch1 />
          </Route>
          <Route exact path="/detail/ResearchExperiments">
               <ResearchExperiments />
             </Route>

          <Route exact path="/detail/Happy">
               <Happy />
             </Route>
         <Route exact path="/detail/Bicycle">
              <Bicycle />
            </Route>
            <Route exact path="/detail/CuratedByInstagram">
                 <CuratedByInstagram />
               </Route>


		   <Route exact path="/detail/Vellichor">
            <Vellichor />
          </Route>
		   <Route exact path="/detail/24fps">
            <TwentyFourFPS />
          </Route>

        </Switch>
      </div>

    </BrowserRouter>
  );
}

export default App;
