// pages/CeramicsExperiments.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './template.css';
import React from "react";
import {Helmet} from "react-helmet";

const CeramicsExperiments = () => (
	<div class="container">
	<Helmet>
			 <meta charSet="utf-8" />
			 <meta
					name='viewport'
					content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
				/>
			 <title>rithwik j. (ceramic exp)</title>
	 </Helmet>

		<div class="row">
		<div class="mt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
			<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="Back Arrow">
				<g id="Arrow - Left 2">
				<path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
				</g>
				</svg>
			</a>
		</div>

			<div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-9 col-xl-9" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
					<div id="ProjectTitle">Experiments with Ceramics</div>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Timestamp</div>
					<div class="description">August 2020</div>
				</a>
			</div>


		</div>
		<div class="row">

		</div>

		<div class="row">
			<div class="col-12 col-s-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<p class=" mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'Roslindale-Bold', 'fontSize':'28pt'}}>Pottery</p>
					I’ve recently had the chance to borrow a pottery wheel from someone nearby, and I've had the chance to play with it quite regularly over the past few months. The initial few days were quite a struggle to get the hang of centering the clay, but with that down, it really became a lot more enjoyable.
					</p>
					<p class=" mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">	Throwing off the hump seemed to be easier since I was able to only focus on centering as much clay as I needed and not stop and start the wheel if I made a mistake.
					</p>
					<p class=" mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">I was working with Red Clay, which only after ruining a t-shirt and two walls did I realise, contained Iron Oxide as a major component. I was also able to get a nice recycling process going in a week, using a plaster bed to absorb the moisture from the wobbly pots and trimmings.
					</p>

					<p class=" mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'Roslindale-Bold', 'fontSize':'28pt'}}>Wheel</p>

					Initially, this started as an attempt to build a wheel, which I attempted to do using a motor extracted from a ceiling fan. It wasn’t possible to get a belt or any sort of machinery to fabricate fittings, so the stability sort of .. sucked:
					</p>

					<div class="col-12 col-md-12 col-xl-12">
						<a class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 .d-block">
							<video src="../images/CeramicsExperiments/VID_22900724_165103_796.mp4" class="d-block" alt="..." controls autoplay></video>
						</a>
					</div>
					<div class="col-12 col-md-12 col-xl-12">
						<a class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 .d-block">
							<video src="../images/CeramicsExperiments/VID_22901226_084729_989.mp4" class="pt-5 d-block" alt="..." controls autoplay></video>
						</a>
					</div>
					<p class=" mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'Roslindale-Bold', 'fontSize':'28pt'}}>Onward</p>

					While I don’t have many pictures because my hands were usually dirty while working, I’m looking to work on a custom wheel, perhaps with some improvements for the aspects that seem harder—centering pots for trimming, levelling, hot–pluggable attachments for other tools, come to mind from just my experience.<br />
					I’ve also been playing with computational design for ceramics, more on that <a style={{"font-family":"Roslindale-Regular"}} href="../detail/GenerativeCeramics"><u>here.</u></a></p>
				</a>
			</div>

		</div>
	</div>
);

export default CeramicsExperiments;
