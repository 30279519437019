// pages/About.js

import React from "react";
import {Helmet} from "react-helmet";

const Contact = () => (
  <div>
  <Helmet>
       <meta charSet="utf-8" />
       <meta
          name='viewport'
          content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
        />
       <title>rithwik j.</title>
   </Helmet>

    <h1 className="mt-0 pt-0">This is the Contact Page</h1>
    <p>
      Contact 123
    </p>
  </div>
);

export default Contact;
