// pages/Detail.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './template.css';
import React from "react";
import {Helmet} from "react-helmet";


const Detail = () => (
	<div class="container">
	<Helmet>
			 <meta charSet="utf-8" />
			 <meta
					name='viewport'
					content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
				/>
			 <title>rithwik j. (All Content)</title>
	 </Helmet>

		<div class="row">
		<div class="mt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
			<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="Back Arrow">
				<g id="Arrow - Left 2">
				<path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
				</g>
				</svg>
			</a>
		</div>

			<div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-9 col-xl-9" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
					<div id="ProjectTitle">All Content and Nodes</div>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Update Date</div>
					<div class="description">May 16th 2021</div>
				</a>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-8 col-xl-9">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Description</div>
					<div class="description">This page serves both as the accessible version of this site as well as a way to navigate and view all the content here at a glance. Display projects are pruned regularly(ish) but other content should hang around.</div>
				</a>
			</div>
		</div>

		<div class="row">

		</div>

		<div class="row">
			<div class="col-12 col-md-12 col-sm-12 col-xl-6">
				<a class="mx-xl-0 px-xl-0 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'Roslindale-Bold', 'fontSize':'28pt'}}>Projects</p>
						<a href="./Slate" style={{"fontSize": "22px"}}>[2020] <u>Braille Slate for Higher Mathematics</u></a><br />
					<a href="./Muse" style={{"fontSize": "22px"}}>[2020] <u>Script Typeface Design</u></a><br />
					<a href="./WakeUpVote" style={{"fontSize": "22px"}}>[2018] <u>Wake Up and Vote</u></a><br />
					<a href="./Abhilasha" style={{"fontSize": "22px"}}>[2018] <u>Abhilasha</u></a><br />
						<a href="./LightSupport" style={{"fontSize": "22px"}}>[2019] <u>The Light Support</u></a><br />
					</p>
				</a>
			</div>

			<div class="col-12 col-md-12 col-sm-12 col-xl-6">
				<a class="mx-xl-0 px-xl-0 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'Roslindale-Bold', 'fontSize':'28pt'}}>Experiments</p>
						<a href="./CeramicsExperiments" style={{"fontSize": "22px"}}>[Jul 2020] <u>Experiments with Ceramics</u></a><br />
						<a href="./GenerativeCeramics" style={{"fontSize": "22px"}}>[Aug 2020] <u>Generative+Ceramics</u></a><br />
						<a href="./ResearchExperiments" style={{"fontSize": "22px"}}>[May 2021] <u>How can we use data visualization upfront to collect better data?</u></a><br />

					</p>
				</a>
			</div>
		</div>

		<div class="row">
		<div class="col-12 col-md-9 col-xl-9">
			<a class="mx-xl-0 px-xl-0 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
				<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
				<p style={{'font-family': 'Roslindale-Bold', 'fontSize':'28pt'}}>Writing</p>
				<a href="./CuratedByInstagram" style={{"fontSize": "22px"}}>[August 2021] <u>Curated by Instagram—The next generation of journalism</u></a><br />
					<a href="./UserResearch1" style={{"fontSize": "22px"}}>[Dec 2020] <u>Modern Research Tooling</u></a><br />
					<a href="./" style={{"fontSize": "22px"}}>[(wip.) April 2020] <u>Reflections and mistakes on a thesis project</u></a><br />
				</p>
			</a>
		</div>
		</div>



	</div>
);

export default Detail;
