// pages/CuratedByInstagram.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './templateLight.css';
import React from "react";
import { Helmet } from "react-helmet";

const CuratedByInstagram = () => (
	<div class="container">
		<Helmet>
			<meta charSet="utf-8" />
			<meta
				name='viewport'
				content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
			/>
			<title>rithwik j. (Curated By Instagram.)</title>
		</Helmet>


		<div class="row">
			<div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
				<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g id="Back Arrow">
							<g id="Arrow - Left 2">
								<path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</g>
						</g>
					</svg>
				</a>
			</div>

			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-9 col-xl-9" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
					<div id="ProjectTitle">Curated by Instagram</div>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Timestamp</div>
					<div class="description">25th August 2021</div>
				</a>
			</div>


		</div>
		<div class="row">

		</div>

		<div class="row">
			<div class="col-12 col-s-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0  d-flex flex-column justify-content-between p-3 .d-block">

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						It has always been endlessly fascinating that a singular defining characteristic of our times is our ability to forget. While not quite forgiveness, this amnesia seems to hold a pervasive sway over the minds of those who continue to masochistically crawl back (not unlike getting back with an ex) to the same media outlets that have shown themselves to be nothing but peddlers of pale, yellow journalism, each time with the belief that 'things have changed'.

					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						Well written articles seldom give away the biases of the author; the nouveau media has no such higher ambitions—reporters will denigrate themselves starting with the first words in the headline. It indeed appears to be a race to the bottom—the more you are able to debase yourself, the proportionally higher becomes your readership.

					</p>

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						But more perverse is the utter lack of skin in the game that comes with the post. To willingly misrepresent is abhorrent enough, but to think that they might have unwittingly done so is worse—because they possess both influence and power while having none of the accountability. By choosing not to include the author's name alongside pieces, does the organization signal that the work represents the views of the collective? Perhaps then some good old fashioned stockading or internment at a labour camp is back on the menu.
					</p>

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						The fact that you can have two of these things without the other seems a bit suspect. What happens to a bad journalist? What (internal) punishment is meted out to those who cause damage through their own negligence? The fact that you can't know these answers for media should indicate how excessive the trust placed on their shoulders is. And this isn't even touching upon their regularly publicized ineptitude combined with a strong Dunning–Kruger complex.
					</p>

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						A few months ago, a publicized alleged rape case was accepted by a court on a <i>prima facie</i> basis. Earlier this week, with the investigation reports and forensic results on hand (which are sealed), the defendant was released on bail, as it was the court's opinion that among other things, the defendant was unlikely to coerce, intimidate witnesses and otherwise repeat the alleged offences once released.
					</p>

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						Now, while it is in the interest of the media to misconstrue and misrepresent (never trust those whose motivations are money, eyeballs or reactions—the grand trifecta of modern publications), a recent phenomenon is the proclivity of the rest of the population to imbibe and vomit out this signature blend into a comment box, prostituting their values and beliefs for a few followers or likes.
					</p>

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						In a certain sense, social media has become a platform to promote a personal brand, an agenda or more narcissistically–yourself. In order to 'game the algorithm', one must be ready from the drop with a hot–take on said trending topic—or in the absence of one, a mendacious strongly worded reaction to events you possess no working knowledge of, will see you soar to the top of the morass that is to become of the comments.
					</p>

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						The perversion continues well into the responses that roll in from their readership, a horde that relies on number to drown out the fact that their opinions are neither well–reasoned nor (perhaps, more critically) original. You climb up on that soapbox and let loose streams of vitriol with the shallow motivations of picking up a few likes.
					</p>

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						In this particular case, even more baffling was the horde of men seemingly overcompensating for a perceived slight against the rights of women across the country. What drives this behaviour? It comes off as nothing more than an uncomfortable courtship display to be the 'nice guy'—<i>because not all guys are like that.</i>
					</p>

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						Do you imagine that to adopt anything but absolute, public condemnation of an undertrial with nothing more than loose, second–hand retelling by a party that as we've established, relies on this outrage to drive sales, reflects well on your ability, or worse, willingness to reason?
					</p>

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						It's disturbing that one can cultivate a personal echo-chamber in the public, all egged on with the dopamine from likes and new followers.
					</p>

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						I'm glad there are now tools to let me filter out words and topics from my social media consumption. Does not believing in any news media make me a nihilist? Perhaps it does.
					</p>

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						But in the end, the realisation that I'd sooner go back to my personal echo-chamber than exist in this orgiastic public one, which serves no purpose other than to incense me at its continued failure to act like a forum for discourse allowing itself to instead be tailored for new hit–pieces.
					</p>



				</a>
			</div>

		</div>
	</div>
);

export default CuratedByInstagram;
