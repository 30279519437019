// pages/About.js

import React from "react";
import {Helmet} from "react-helmet";

const About = () => (
  <div class="container">
  <Helmet>
       <meta charSet="utf-8" />
       <meta
          name='viewport'
          content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
        />
       <title>rithwik j. (about)</title>
   </Helmet>

    <div class="row">
    <div class="mt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
      <a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Back Arrow">
        <g id="Arrow - Left 2">
        <path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        </g>
        </svg>
      </a>
    </div>
      <div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-12 col-xl-12" id="title">

        <h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
          <div id="ProjectTitle">About</div>
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-7 col-xl-7">
        <a class="d-flex flex-column justify-content-between p-3 .d-block">
          <div class="label">Contact</div>
          <div class="description">hi@rithwik.me</div>          
          <div class="description">i.rithwik@gmail.com</div>

          {/* <div class="description">+91 Nine–Six–Six–Three–Eight–Zero–Four–Four–Nine–Eight</div> */}

        </a>
      </div>


    </div>
    <div class="row">
      <div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 mr-0 col-12 col-md-6 col-xl-6 pr-5">
        <a class="d-flex flex-column justify-content-between p-3 .d-block">
          <div class="label">Description</div>
          <div class="description">I like solving wicked problems. Designer who writes code, my hobby is making bits and atoms misbehave.<br />
In the past, I’ve founded and run a studio called Clay Gorilla to do good design.</div>
        </a>
      </div>
      <div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 mr-0 col-12 col-md-6 col-xl-6 pr-5">
        <a class="d-flex flex-column justify-content-between p-3 .d-block">
          <div class="label">On the Web</div>
          <div class="description">
          <a href="https://www.goodreads.com/user/show/113512446-rithwik"><u>Goodreads</u></a><br />
          <a href="https://www.twitter.com/thel3l"><u>Twitter</u></a><br />
          {/* <a href="https://www.instagram.com/rithwikjayasimha/"><u>Instagram</u></a><br /><br /> */}
          <a href="../"><u>Recursion?</u></a><br />
          </div>
        </a>
      </div>
    </div>
  </div>
);

export default About;
