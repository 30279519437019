// pages/GenerativeCeramics.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './template.css';
import React from "react";
import {Helmet} from "react-helmet";

const GenerativeCeramics = () => (
	<div class="container">
					<Helmet>
							 <meta charSet="utf-8" />
							 <meta
							    name='viewport'
							    content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
							  />
							 <title>rithwik j. (gen. ceramics)</title>
					 </Helmet>


		<div class="row">
		<div class="mt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
			<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="Back Arrow">
				<g id="Arrow - Left 2">
				<path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
				</g>
				</svg>
			</a>
		</div>

			<div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-9 col-xl-9" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
					<div id="ProjectTitle">Generative Ceramics</div>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Timestamp</div>
					<div class="description">July 2020</div>
				</a>
			</div>


		</div>
		<div class="row">

		</div>

		<div class="row">
			<div class="col-12 col-s-12 col-md-12 col-xl-12">
					<a class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0  d-flex flex-column justify-content-between p-3 .d-block">

					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'Roslindale-Bold', 'fontSize':'28pt'}}>Reaction Diffusion</p>
					Been trying out simulations of Reaction Diffusion systems based off the Gray–Scott model. The goal here would be to grow and deform three dimensional meshes computationally, which I currently see as being either for aesthetics, similar to the work of Nervous Systems and New Balance’s shoes or as a way to improve the function of an existing product—grip, aerodynamics and finish.
					</p>
					<div class="col-12 col-md-12 col-xl-12">
						<a class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 .d-block">
							<img src="../images/GenerativeCeramics/Eouuj43XYAcATmH.jpeg" class="pt-5 d-block" alt="..."  ></img>
							<img src="../images/GenerativeCeramics/Eouv0r3WMAA2Wib.jpeg" class="pt-5 d-block" alt="..."  ></img>
						</a>
					</div>
					<p class="pt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">	The first stage was to first understand how to write the code to create a texture along a two dimensional surface. I based my script for the Reaction Diffusion model off of <a style={{"font-family":"Roslindale-Regular"}} href="https://www.karlsims.com/rd.html"><u>Karl Sims’ explanation.</u></a>
					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">I also test drove <a style={{"font-family":"Roslindale-Regular"}} href="https://github.com/GollyGang/ready"><u>GollyGang’s ready</u></a> which is available on GitHub,to play with the kill and feed rates to see what looked interesting.
					</p>

					<div class="col-12 col-md-12 col-xl-12">
						<a class="pt-0 mt-0 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between center .d-block">
							<video src="../images/GenerativeCeramics/VID_20840313_121225_082.mp4" class="d-block" alt="..."  controls autoplay></video>
						</a>
					</div>

					<p class="pt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">I then wrote a rough grasshopper sketch to visualize the program flow. It grows the pattern onto a fixed flat mesh, and then uses the mesh as a displacement map projected onto the surface of the solid. I’m sure there’s a more efficient way to carry this out, which I’ll explore when I port this code to OpenFrameworks next month.
					</p>
					<div class="col-12 col-md-12 col-xl-12">
						<a class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between center .d-block">
							<img src="../images/GenerativeCeramics/sample-cup.jpg" class="pt-5 d-block" alt="..." ></img>
						</a>
					</div>

				</a>
			</div>

		</div>
	</div>
);

export default GenerativeCeramics;
