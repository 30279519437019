// pages/UserResearch1.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './template.css';
import React from "react";
import {Helmet} from "react-helmet";

const UserResearch1 = () => (
	<div class="container">
					<Helmet>
							 <meta charSet="utf-8" />
							 <meta
							    name='viewport'
							    content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
							  />
							 <title>rithwik j. (user research part a.)</title>
					 </Helmet>


		<div class="row">
		<div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
			<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="Back Arrow">
				<g id="Arrow - Left 2">
				<path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
				</g>
				</svg>
			</a>
		</div>

			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-9 col-xl-9" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
					<div id="ProjectTitle">Modern User Research Tooling Experiments</div>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Timestamp</div>
					<div class="description">December 2020</div>
				</a>
			</div>


		</div>
		<div class="row">

		</div>

		<div class="row">
			<div class="col-12 col-s-12 col-md-12 col-xl-12">
					<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0  d-flex flex-column justify-content-between p-3 .d-block">

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'MuseoSans_700', 'fontSize':'28pt'}}><b>Research Methodology</b></p>
					I've been thinking about research methodology that we use for quantitative data collection. We recently had to create and perform some analysis on data collected from people in various projects—however, when you send out a form, you’re overwhelmingly only getting data from those who seem to either  have some sort of stake in the issue being dealt with (not always bad) or your classmates (which is bad, and would explain why so many research papers have samples with a median age in the low twenties). No one is willing to spend twenty minutes of their time to write free form answers without seeing something in it for them.

					</p>

					<p class="pt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">	I think there are three main areas that need significant work:<br />
					<ol>
				<li>How do you incentivise people to fill in a questionnaire that they may not have a personal interest or stake in? Furthermore, how do you obtain data through a mode that is more convenient for the respondent?</li>
				<li>How do you ensure that the questions you are asking haven’t been massaged to suit the platform that you’re using to obtain the data?</li>
				<li>How do you obtain insights that are more accurate while reducing the cognitive load on the respondent?</li>
					</ol>

					</p>

					<p class="pt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">

					I’ve been trying to offer monetary incentives to fill out a form or perform a task—including giving away 0.01ETH to my class to play a random contest to see how much time people would invest into a random pursuit if motivated. I’m not sure it’s a very sustainable idea though.<br />
As part of another project this year, I also tried a promise to mass mail results to a particularly controversial research topic to see if it could convince people to fill in a form by including them in the project, but seeing as there was no control, that exercise was doomed to fail.
				</p>
					<p class="pt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'MuseoSans_700', 'fontSize':'28pt'}}><b>Onward</b></p>

					I’ll be working on this in some way—I’m going to have to do research this semester as well, and since I now have a place to host these experiments, some of of the things that I’ll be looking at (one for each of the aforementioned categories) might be: <br />
						<ol>
							<li>Using alternate platforms to obtain data and insights (IVRs, WhatsApp bots, SMS, Voice Recordings?)
</li>
							<li>Bubble sorts to only offer say, two options at a time to a person and then perform the actual ranking in the background (as opposed to making them sort a list or rate items on a scale of 1—5)</li>
							<li>Use variable rating scales to determine how someone truly feels—First ask for a response on a scale of 10, then drop it to 5 and then to 3? Seems like a neat way to drill down onto a real location on the number line</li>
						</ol>

				</p>

				</a>
			</div>

		</div>
	</div>
);

export default UserResearch1;
