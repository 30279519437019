import React from "react";
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter} from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker';
// import "bulma/css/bulma.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));

// const getColor = n => '#' + ((n * 1234567) % Math.pow(2, 24)).toString(16).padStart(6, '0');
// const [cooldownTicks, setCooldownTicks] = useState(undefined);


	// ReactDOM.render(

	// 	<ForceGraph2D
	// 		//   enableZoomPanInteraction={false}
	// 		// zoomToFit={400}
	// 		cooldownTime={8000}
	// 		//    cooldownTicks={100}
	// 		// d3AlphaDecay={0.7}
	// 		// d3VelocityDecay={0.4}
	// 		nodeVal={80}//  change the node hitbox
	// 		linkVisibility={true}
	// 		linkColor={"#000"}
	// 		graphData={data}
	// 		d3VelocityDecay={0.3}

	// 		//   cooldownTicks={cooldownTicks}
	// 		//   onEngineStop={() => setCooldownTicks(0)}
	// 		nodeAutoColorBy="group"
	// 		nodeResolution={64}
	// 		nodeCanvasObject={(node, ctx, globalScale) => {
	// 			const label = node.id;
	// 			const fontSize = 16 / globalScale;
	// 			ctx.font = `${fontSize}px Sans-Serif`;
	// 			const textWidth = ctx.measureText(label).width;
	// 			const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 1.6); // some padding

	// 			ctx.fillStyle = '#000';
	// 			ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, bckgDimensions[0], bckgDimensions[1]);

	// 			ctx.textAlign = 'center';
	// 			ctx.textBaseline = 'alphabetic';
	// 			ctx.fillStyle = node.color;
	// 			ctx.fillText(label, node.x, node.y);
	// 		}}


	// 		onNodeDragEnd={node => {
	// 			node.fx = node.x;
	// 			node.fy = node.y;
	// 			node.fz = node.z;
	// 		}}
	// 	/>,
	// 	document.getElementById('graph')
	// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
