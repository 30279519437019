// pages/24fps.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './template.css';
import React from "react";
import {Helmet} from "react-helmet";


const TwentyFourFPS = () => (
	<div class="container">
	<Helmet>
			 <meta charSet="utf-8" />
			 <meta
					name='viewport'
					content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
				/>
			 <title>rithwik j. (24fps)</title>
	 </Helmet>

		<div class="row">
		<div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
			<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="Back Arrow">
				<g id="Arrow - Left 2">
				<path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
				</g>
				</svg>
			</a>
		</div>

			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-9 col-xl-9" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
					<div id="ProjectTitle">Movies</div>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Update Date</div>
					<div class="description">December 22nd 2020</div>
				</a>
			</div>
		</div>

		<div class="row">

		</div>

		<div class="row">
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-0 px-xl-0 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'Roslindale-Bold', 'fontSize':'28pt'}}>Random</p>
						☐ Room<br />
						☑️ Diary of a Teenage Girl<br />
						☑️ Portrait de la jeune fille en feu <br />
						☑️ Searching <br />
						☑️ The Watchtower of Turkey <br />
						☐ Dogman<br />
						☐ Sorry we missed you<br />
						☐ Tikli and Laxmi bomb<br />
						☐ Kumbalaani heights<br />
						☐ Laurence anyways<br />
						☐ Mudbound<br />
						☐ Room<br />
						☑️ A Russian Youth<br />

					</p>


				</a>
			</div>
		</div>
	</div>
);

export default TwentyFourFPS;
