// pages/Muse.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './template.css';
import React from "react";
//
import {Helmet} from "react-helmet";

const Muse = () => (
	<div class="container">
	<Helmet>
			 <meta charSet="utf-8" />
			 <meta
					name='viewport'
					content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
				/>
			 <title>rithwik j. (muse)</title>
	 </Helmet>

		<div class="row">
		<div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
			<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="Back Arrow">
				<g id="Arrow - Left 2">
				<path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
				</g>
				</svg>
			</a>
		</div>

			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-9 col-xl-9" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
					<div id="ProjectTitle">Muse—Script Typeface Design</div>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-2 col-xl-2">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Timestamp</div>
					<div class="description">(Work In Progress) December 2020</div>
				</a>
			</div>
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-1 col-xl-1">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Type</div>
					<div class="description">Digital</div>
				</a>
			</div>
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 mr-0 col-12 col-md-4 col-xl-4 col-s-2 pr-5">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Description</div>
					<div id="description">A script typeface designed based off a movie. The movie chosen was the Ship of Theseus. The typeface attempts to follow the themes in the movie, talking about the transience of life, the ego and boundaries and finally, reduction to primal instincts. </div>
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 .d-block">
					<img src="../images/Muse/bb-07.png" class="pt-5 d-block" alt="..."/>
				</a>
			</div>
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 .d-block">
					<img src="../images/Muse/bb-06.png" class="pt-5 d-block" alt="..."/>
				</a>
			</div>
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<img src="../images/Muse/1.png" class="pt-5 d-block" alt="..."/>
				</a>
			</div>

		</div>

		<div class="row">

		</div>
	</div>
);

export default Muse;
