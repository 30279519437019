// pages/Happy.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './template.css';
import React from "react";
import {Helmet} from "react-helmet";

const Happy = () => (
	<div class="container">
	<Helmet>
			 <meta charSet="utf-8" />
			 <meta
					name='viewport'
					content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
				/>
			 <title>today, i decided to be happy.</title>
	 </Helmet>

		<div class="row">
		<div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
			<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="Back Arrow">
				<g id="Arrow - Left 2">
				<path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
				</g>
				</svg>
			</a>
		</div>

			<div class="mx-xl-0 px-xl-0 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-0 col-xl-0" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold text-center">
					<div id="ProjectTitle" class="text-center">today, I decided to be happy.</div>
				</h2>
			</div>
		</div>

		<div class="row">

		</div>

		<div class="row">
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-0 px-xl-0 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 text-center">
					Today, I woke up and decided to be happy.<br />
					I ate my food, I read a book<br />
					I played a game and I laughed (a lot)<br />
					for today, I was happy.<br /><br />

					My ears did not ring<br />
					and I looked forward <br />to what the day might bring.<br />
					My mind did not freeze<br />
					(hell, I didn't even feel sick!)<br />
					because my happy places <br />were mine once again<br />
					for today, I let things go.<br /><br />

					I let my mask drop<br />
				  my face finally matching the prop<br />
					I was my own function again<br />
					and none of me a consequence of you<br />
					for today, I smiled for real.<br /><br />

					I accepted the choices<br />
					the words—said and unsaid<br />
					the actions and the thoughts<br />
					the hesistancy and the doubt.<br />
					I didn't even wonder <br />if friendship was an exchange<br />
					for today, I was in that kind of a mood.<br /><br />

					So at night, as I go to bed<br />
					as I reflect upon my day<br />
					I'll realize that this stagnation isn't okay.<br />
					But who knows?<br /><br />
					Maybe tomorrow.<br />
					Tomorrow will be my happy day.<br /><br />
					</p>


				</a>
			</div>
		</div>
	</div>
);

export default Happy;
