// pages/ResearchExperiments.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './template.css';
import React from "react";
import {Helmet} from "react-helmet";

const ResearchExperiments = () => (
	<div class="container">
					<Helmet>
							 <meta charSet="utf-8" />
							 <meta
							    name='viewport'
							    content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
							  />
							 <title>rithwik j. (user research part b.)</title>
					 </Helmet>


		<div class="row">
		<div class="mt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
			<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="Back Arrow">
				<g id="Arrow - Left 2">
				<path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
				</g>
				</svg>
			</a>
		</div>

			<div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-9 col-xl-9" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
					<div id="ProjectTitle">How can we use data visualization upfront to collect better data?</div>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Update Date</div>
					<div class="description">May 16th 2021</div>
				</a>
			</div>


		</div>
		<div class="row">

		</div>

		<div class="row">
			<div class="col-12 col-s-12 col-md-12 col-xl-12">
					<a class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0  d-flex flex-column justify-content-between p-3 .d-block">

					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
						<p style={{'font-family': 'MuseoSans_700', 'fontSize':'28pt'}}><b>Half baked ideas</b></p>
				From my previous thoughts on <b><u><a href="/detail/UserResearch1" style={{'font-family': 'Roslindale-Regular'}}>improving the state of user research methods</a></u></b>, came a couple of ideas that I'm detailing below. These are probably going to be used in order to work on a survey soon to paint the landscape of design education in India very soon. <br />
				The focus of these exercises is to see how we can use visualization during the process of data collection instead of as an afterthought.<br />
				Couple of ideas I'm playing with right now:<br />
					</p>

					<p class="pt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'MuseoSans_700', 'fontSize':'21pt'}}><b>Bubble Sort Questionnaires</b></p>
					<img src="../images/ResearchExperiments/1.png" class="pb-5 d-block" alt="..."/>
					Current systems to arrange and rate information on a scale of 1–5 or 1–10 are inaccurate and make it hard for people to make a meaningful choice.<br />
					A bubble sort based questionnaire would have O(n<sup>2</sup>) time complexity in the worst of cases, but the cognitive load is significantly lower than existing methodology. <br />
					In fact, I'd wager that it would take less time to finish this questionnaire than a ranking type question.
					</p>

					<p class="pt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'MuseoSans_700', 'fontSize':'21pt'}}><b>Rubberband sorts</b></p>
					<img src="../images/ResearchExperiments/2.png" class="pb-5 d-block" alt="..."/>
					Location is in important quantity in daily life—what if we used that in a 2 dimensional plane to identify important items to someone centered around a central point?<br />
					Radial distance = importance? <br />
					We could probably round up or down to the nearest integer to get more standardized data since being able to place a node anywhere on a canvas might not be very repeatable.
					</p>

					<p class="pt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'MuseoSans_700', 'fontSize':'21pt'}}><b>Reinterpreting the Likert</b></p>
					<img src="../images/ResearchExperiments/3.png" class="pb-5 d-block" alt="..."/>
					The Likert scale is often used to force people to make a choice (indeed, it's often called a 'forced choice' method) about their preferences and provide answers in a numerical format that can later be analyzed (we can argue about how it's inconsistent and inaccurate too).
					<br />
					What about asking the same question a couple of times, while varying the scale that is available to choose from in order to gradually obtain their preferences in a more organic manner? It's probably possible to argue that the detail offered by this average is more accurate than the choices offered today.
					</p>

					<p class="pt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'MuseoSans_700', 'fontSize':'21pt'}}><b>Size Matters</b></p>
					<img src="../images/ResearchExperiments/4.png" class="pb-5 d-block" alt="..."/>
					Playing with the concept of using size—each image would be a node on a canvas that is resizable—in order to allow people to provide information about preferences in a more natural fashion. <br />
					Crudely put: Big good, small bad. <br />
					The size of a node determine the importance of the item in the ranking.
					We could possibly use snapping at certain points if discrete values are required.
					</p>

					<p class="pt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					<p style={{'font-family': 'MuseoSans_700', 'fontSize':'21pt'}}><b>Ranking on a Comparitive Scale</b></p>
					<img src="../images/ResearchExperiments/5.png" class="pb-5 d-block" alt="..."/>
					Current systems to arrange and rate information on a numerical scale are inaccurate and make it hard for people to make a meaningful choice.
					<br />
					How about allowing them to arrange the image on either a 'hidden' scale (freely) or into preset columns. This makes it easier to see how everything compares to each other.<br />
					These scales don't even need to be visible to the subject.
					</p>

				</a>

			</div>

		</div>
	</div>
);

export default ResearchExperiments;
