// pages/ParkinsonsAdaptive.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './templateLight.css';
import React from "react";
import { Helmet } from "react-helmet";

const ParkinsonsAdaptive = () => (
	<div class="container">
		<Helmet>
			<meta charSet="utf-8" />
			<meta
				name='viewport'
				content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
			/>
			<title>rithwik j. (clothing adaptations for patients with parkinson's)</title>
		</Helmet>

		<div class="row">
			<div class="mt-5 mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
				<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g id="Back Arrow">
							<g id="Arrow - Left 2">
								<path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
							</g>
						</g>
					</svg>
				</a>
			</div>

			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-9 col-xl-9" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
					<div id="ProjectTitle">Designing Clothing Adaptations for Patients with Parkinson's</div>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-2 col-xl-2">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Timestamp</div>
					<div class="description">December 2021—May 2022</div>
				</a>
			</div>
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-1 col-xl-1">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Type</div>
					<div class="description">Product</div>
				</a>
			</div>
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 mr-0 col-12 col-md-4 col-xl-4 col-s-2 pr-5">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Description</div>
					<div class="description">Frugal adaptations designed to allow patients with Parkinson's to adapt their existing clothing and clothe themselves with dignity.</div>
				</a>
			</div>
			<div class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-2 col-xl-2">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Project Presentation</div>
					<div class="description"><a href="https://www.figma.com/proto/EAY9I5nPg4j68Aj5lslqb8/BTP-Presentations?node-id=700%3A21&scaling=scale-down&page-id=700%3A20"><u>View</u></a></div>
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-0 px-xl-0 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0  d-flex flex-column justify-content-between .d-block">

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between  px-3 py-3 .d-block">
						<p class="pt-3" style={{ 'font-family': 'Roslindale-Bold', 'fontSize': '20pt' }}>Introduction</p>
						Every person deserves the chance to live their life with dignity. This is the central theme and only goal of my thesis project—to enable those who are severely affected with neurological and physical disabilities to live a life that offers them the same chance at an equal, independent life that neurotypical and non–physically impaired people have.

					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between  px-3 py-3 .d-block">
						No one should have to dread the process of getting dressed in the morning because it involves asking a loved one or a carer to help shoehorn them into clothing that is neither adapted nor comfortable.

					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between  px-3 py-3 .d-block">
						No one should dread having to use the bathroom because the process of wearing their pants is a several minute long affair. And no one should have to make the decision to wear only a single ill–fitting Dri–FIT shirt all day long because it reduces the number of times that they will have to suffer this indignity in front of people who shouldn’t have to see them in such a state.

					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 py-3  .d-block">
						And yet, this is exactly the position that the majority of patients with Parkinson’s currently undergo or will soon undergo, a fact that they are all too aware of. Forget playing the guitar or drawing—wearing clothes that fit well is a nightmare for patients because of how inaccessible current clothing is to them. Buttons are impossible to align and insert up and cause cramping because of the repetitive motions required. Zippers are badly positioned and clothes offer little to no give which makes wearing the clothing a huge hassle.
					</p>
				</a>
			</div>


			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-0 px-xl-0 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0  d-flex flex-column justify-content-between .d-block">

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 py-3 .d-block">
						<p class="pt-3" style={{ 'font-family': 'Roslindale-Bold', 'fontSize': '20pt' }}>About Parkinson's</p>
						Parkinson’s is a neurodegenerative disease that affects an individual’s ability to control their muscles—both voluntary and involuntary. It is believed to be caused as a result of a dopamine insufficiency in the brain that leads to a decline in muscular control.
					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 py-3 .d-block">
						As a result, as time progresses, individuals lose their ability to control their muscles and therefore end up requiring additional help in order to perform basic activities in their lives. Like most other degenerative diseases, a scale is often used to represent how severe the disease progression is on patients at present. The most common of these is the Hoehn and Yahr rating scale for Parkinson’s Disease from 1967 that recognizes there to be five stages in the disease.
					</p>
				</a>
			</div>

			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<img src="../images/ParkinsonsAdaptive/test1.53.png" class="pt-2 d-block" alt="..." />
					<div id="ImageDescription" class="pt-2">Testing packaging layout. The solution was intended to be sold as a solution that users could install themselves onto existing clothes. </div>
				</a>
			</div>

			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-0 px-xl-0 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0  d-flex flex-column justify-content-between .d-block">

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between  px-3 py-3 .d-block">
						<p class="pt-3" style={{ 'font-family': 'Roslindale-Bold', 'fontSize': '20pt' }}>About the Solution</p>
						As a design project that sought to serve an already marginalized community, it was always the objective of this design project to create designs that could be easily adopted and incorporated into existing clothing by patients taking the designs and their clothes for alterations to tailors. With this in mind, there was a focus on creating solutions that could be easily adapted into existing clothing designs as well as identifying ways to allow the design to be turned into a model that would be used to create such clothing in the future.

					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between  px-3 py-3 .d-block">
					However, after just the initial conversations with patients, it was obvious that there existed several glaring issues in their basic routines that remained unresolved.


					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between  px-3 py-3 .d-block">
					As a result, it was decided to focus upon the topic of clothing since this was an issue that the patients seemed passionate about. Their issues with clothing were particularly painful as it affects their dignity more than any other issue—the inability to clothe oneself without the aid of a helper was an indignity that was felt very deeply.

					</p>
			
				</a>
			</div>

			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 py-3 .d-block">
					<img src="../images/ParkinsonsAdaptive/buttons.jpg" class="pt-2 d-block" alt="..." />
					<div id="ImageDescription" class="pt-2">Prototype solution that consisted of a TPU receptacle and a shaped ABS button for easier insertion.</div>

				</a>
			</div>

			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-0 px-xl-0 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0  d-flex flex-column justify-content-between .d-block">

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between  px-3 py-3 .d-block">
						<p class="pt-3" style={{ 'font-family': 'Roslindale-Bold', 'fontSize': '20pt' }}>Insights</p>
							 <b>— Should grow with patients and not feel like it’s degrading</b>	
							 <b>— Larger fasteners are easier to handle for patients</b>	Reduced mobility means that the larger the fastener hit box, the more easy it is to wear and remove. <br />
							 <b>— Should be easy to put on and remove for the patient by themselves.</b> Since the goal is to enable self sufficiency, there is no reason to have the clothing require external help to put on and take off.	 <br />
							 <b>— Already accustomed to using one hand to push button into hole.</b>It was observed that in order to deal with rest tremors while holding their mobile phone, a patient would hold out their thumb finger and navigate the phone screen onto their thumb as opposed to having it freely float and touch items on the screen.	 <br />
							 <b>— Clothing options should not look for the infirm.</b>From the first meeting, it was agreed that the clothing options should look like they would suit anyone and not be a statement piece in itself.	 <br />
							 <b>— Joint rigidity prevents users from wearing traditional clothing.</b>Clothing needs to have some give in order to allow it to be worn more easily.	  <br />
							 <b>— Non magnetic accessible solutions.</b>	Magnetic solutions were not viable as they would interfere with the functioning of the DBS implant just under the skin on the patient’s chest. DBS is also the most popular treatment for Parkinson’s today. <br />
							 <b>— Security and easy to wear.</b>	Magnets and velcro while easy to put on do not lend themselves to a feeling of security for long term usage. They also aren’t a very good experience for the end user. <br />
							 <b>— Loose clothing is highly preferred.</b>	 <br />


					</p>			
				</a>
			</div>

			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 py-3 .d-block">
				<p class="pt-3" style={{ 'font-family': 'Roslindale-Bold', 'fontSize': '20pt' }}>Solution</p>
					<img src="../images/ParkinsonsAdaptive/prototype-1.png" class="pt-2 d-block" alt="..." />
					<div id="ImageDescription" class="pt-2">Test fitting prototype onto a shirt with fabric glue.</div>

					<img src="../images/ParkinsonsAdaptive/prototype-3.png" class="pt-4  d-block" alt="..." />
					<div id="ImageDescription" class="pt-2">Closeup of box seam after installation.</div>


					<img src="../images/ParkinsonsAdaptive/prototype-2.png" class="pt-4  d-block" alt="..." />
					<div id="ImageDescription" class="pt-2">Various iterations of the final solution; here—thickness and channel width variations. </div>


				</a>
			</div>


			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 py-3 .d-block">
				<p class="pt-3" style={{ 'font-family': 'Roslindale-Bold', 'fontSize': '20pt' }}>Testing</p>
					<img src="../images/ParkinsonsAdaptive/Untitled.gif" class="pt-2 d-block" alt="..." />
					<div id="ImageDescription" class="pt-2">Patients found the early prototypes easy to wear and take off; here—early user test.</div>

				</a>
			</div>

			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-0 px-xl-0 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0  d-flex flex-column justify-content-between .d-block">

					<p class="mx-xl-5 px-xl-5 mx-md-5 px-md-5 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between px-3 py-3 .d-block">
						<p class="pt-3" style={{ 'font-family': 'Roslindale-Bold', 'fontSize': '20pt' }}>Pricing and Other Considerations</p>
						<b>— Costs ₹ 35 for a set of 10 buttons and receptacles.</b> Even with FDM as the manufacture process. <br />
						<b>— Buttons are scaled up to allow easier grip.</b>	
						<b>— TPU and ABS are both iron–box and washing machine safe.</b>	

					</p>
				</a>
			</div>


		</div>
		<div class="row">

		</div>
	</div>
);

export default ParkinsonsAdaptive;
