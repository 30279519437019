// pages/Bicycle.js
import 'bootstrap/dist/css/bootstrap.min.css';
import './template.css';
import React from "react";
import {Helmet} from "react-helmet";

const Bicycle = () => (
	<div class="container">
	<Helmet>
			 <meta charSet="utf-8" />
			 <meta
					name='viewport'
					content='initial-scale=1.0, width=device-width, shrink-to-fit=yes'
				/>
			 <title>The Bicycle is Dead</title>
	 </Helmet>

		<div class="row">
		<div class="mt-5 mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
			<a class="d-flex flex-column justify-content-between p-3 .d-block" href="/">
				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="Back Arrow">
				<g id="Arrow - Left 2">
				<path id="Stroke 1" d="M15.5 19L8.5 12L15.5 5" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
				</g>
				</g>
				</svg>
			</a>
		</div>

			<div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-9 col-xl-9" id="title">
				<h2 class="d-flex flex-column justify-content-between p-3 .d-block font-weight-bold">
					<div id="ProjectTitle">The Bicycle is Dead, Long Live the Bicycle</div>
				</h2>
			</div>
		</div>
		<div class="row">
			<div class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 col-12 col-md-4 col-xl-4">
				<a class="d-flex flex-column justify-content-between p-3 .d-block">
					<div class="label">Update Date</div>
					<div class="description">May 15th 2020</div>
				</a>
			</div>
		</div>

		<div class="row">

		</div>

		<div class="row">
			<div class="col-12 col-md-12 col-xl-12">
				<a class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0 d-flex flex-column justify-content-between p-3 .d-block">
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					Once upon a time, there existed a toy that used to be the only affordable means for commute for the public in India. This was still an India in it’s nascent stages, in a time when the License Raj held sway over the behaviours of consumers in the country.
					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					With the education that the country provided its citizens came increased awareness. With the liberalization of the economy came choice. And with choice came a dawning realization that the same industries that had once provided these people with a mode of commute, were now both symbols of an institution and a time past.
					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					While it is hard to locate an inflection point, the cycle’s hold over the population continued to wane, until today when it only remains as symbol of the freedom it once offered people.
					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					When this project first began, it was as an attempt to identify why the bicycle, the vehicle of the common man, once so popular has now been relegated to the status of a social outcast. From outside, it appeared that a significant problem was the waste this industry was creating through the need to buy growing children several bicycles as they grow, but it soon became apparent that this was not as significant as initially assumed.
					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					It was becoming apparent that the bicycle had no future. The industry has seen significant stagnation, not merely in terms of technology, but also in making new developments available to the general populace. The industry has continued to fragment itself with shows and exhibitions offering one thing, all while continuing to lock innovation behind exclu- sionary pricing that is available to only the enthusiast.
					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					To the average person, to put nearly the same amount of money that they could purchase a scooter with into a bicycle is both unappetizing as it unjustifiable.
					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					Simultaneously, there has been a significant push from activists and city planners to bring the bicycle back into the vogue through a series of misdirected activities including the public bike sharing systems as well as cyclothons and other activities.
					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					While reactive, this attempt to symptomatically treat the problem is indicative of a far more insidious problem—the fact that people no lon- ger wish to ride a bicycle.
					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					It may be time to stop trying to design a new bicycle, design a public bicycle or design any bicycle at all and start looking at what the future possibilities for this industry are.
					</p>
					<p class="mx-xl-5 px-xl-5 mx-md-0 px-md-0 mx-sm-0 px-sm-0 mx-xs-0 px-xs-0">
					A better question might be—what can we do to make [personal–two–wheeler–transportation] popular again?
					</p>


				</a>
			</div>
		</div>
	</div>
);

export default Bicycle;
