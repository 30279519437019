import React, { useState, useEffect, useRef, useCallback } from "react";
import { ForceGraph2D } from "react-force-graph";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import "./home.css";

const Home = () => {
  const [data, setData] = useState(null);
  const forceRef = useRef();
  const graphElem = useRef(null);
  const imagesCache = useRef({});

  useEffect(() => {
    fetch("./projects.json")
      .then((res) => res.json())
      .then(setData)
      .catch(console.error);
  }, []);

  const handleClick = useCallback((node) => {
    if (node) {
      window.location.href = node.url;
    }
  }, []);

  const loadImage = useCallback((src) => {
    if (!imagesCache.current[src]) {
      imagesCache.current[src] = new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    }
    return imagesCache.current[src];
  }, []);

  const nodeCanvasObject = useCallback(
    (node, ctx, globalScale) => {
      node.fx = node.x;
      node.fy = node.y;
      const label = node.id;
      const fontSize = 10;
      ctx.font = `500 ${fontSize}px Diatype`;
      const textWidth = ctx.measureText(label).width;
      const bckgDimensions = [textWidth, fontSize].map((n) => n + fontSize * 1);

      ctx.fillStyle = `${node.color}`;
      ctx.fillRect(
        node.x - bckgDimensions[0] / 2,
        node.y - bckgDimensions[1] / 2 - 3,
        bckgDimensions[0],
        bckgDimensions[1]
      );

      ctx.textAlign = "center";
      ctx.textBaseline = "alphabetic";
      ctx.fillStyle = "#000000";
      ctx.fillText(label, node.x, node.y);

      node.__bckgDimensions = bckgDimensions;

      if (node.__imgCache) {
        const img = node.__imgCache;
        ctx.drawImage(
          img,
          node.x - bckgDimensions[0] / 2,
          node.y + bckgDimensions[1] / 2,
          img.width / 8,
          img.height / 8
        );
      } else if (!node.__imgLoading) {
        node.__imgLoading = true;
        loadImage(node.thumbnail)
          .then((img) => {
            node.__imgCache = img;
            forceRef.current.refresh(); // This line triggers a re-render immediately after the image loads
          })
          .catch(() => {
            node.__imgLoading = false;
          });
      }
    },
    [loadImage]
  );
  const nodePointerAreaPaint = useCallback((node, color, ctx) => {
    ctx.fillStyle = color;
    const bckgDimensions = node.__bckgDimensions;
    if (bckgDimensions) {
      ctx.fillRect(
        node.x - bckgDimensions[0] / 2,
        node.y - bckgDimensions[1] / 2,
        ...bckgDimensions
      );
    }
  }, []);

  return (
    <BrowserRouter>
      <Navbar id="nav">
        <Navbar.Brand style={{ fontSize: "20pt" }}>
          Hi, I'm Rithwik, this page is the mirror of my scratchpad —
          <a
            href="https://2023.rithwik.me"
            style={{
              fontFamily: "Diatype",
              fontWeight: "400",
              fontSize: "20pt",
              color: "#000000",
            }}
          >
            [
            <u
              style={{
                fontFamily: "Diatype",
                fontWeight: "400",
                fontSize: "20pt",
                color: "#000000",
              }}
            >
              2023 Portfolio
            </u>
            ]
          </a>
          <a
            href="https://twitter.com/thel3l"
            style={{
              fontFamily: "Diatype",
              fontWeight: "400",
              fontSize: "20pt",
              color: "#000000",
            }}
          >
            [
            <u
              style={{
                fontFamily: "Diatype",
                fontWeight: "400",
                fontSize: "20pt",
                color: "#000000",
              }}
            >
              Contact
            </u>
            ]
          </a>
        </Navbar.Brand>
      </Navbar>
      <div id="graph" ref={graphElem}>
        {data && (
          <ForceGraph2D
            ref={forceRef}
            width={window.innerWidth}
            enableZoomInteraction={true}
            cooldownTime={100}
            graphData={data}
            linkColor={() => "rgba(0,0,0,0.2)"}
            nodeColor={"#fff"}
            onNodeHover={(node) =>
              (graphElem.current.style.cursor = node ? "pointer" : null)
            }
            onNodeClick={handleClick}
            linkOpacity={1}
            nodeResolution={64}
            nodeCanvasObject={nodeCanvasObject}
            nodePointerAreaPaint={nodePointerAreaPaint}
            onNodeDragEnd={(node) => {
              node.fx = node.x;
              node.fy = node.y;
              node.fz = node.z;
              console.log(
                `Node ${node.id} moved to new position: x=${node.x}, y=${node.y}, z=${node.z}`
              );
            }}
          />
        )}
      </div>
    </BrowserRouter>
  );
};

ReactDOM.render(<Home />, document.getElementById("root"));

export default Home;
